<template>
  <div class="already-issue-city">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="企业名称">
					<el-input v-model="formSearch.companyName" placeholder="请输入企业名称"></el-input>
				</el-form-item>
				<el-form-item label="下发时间">
					<el-date-picker v-model="formSearch.timeLA" value-format="yyyy-MM-dd" type="daterange" align="right" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptionsLA"></el-date-picker>
				</el-form-item>
				<el-form-item label="下发地市">
					<el-select v-model="formSearch.cityCode" clearable>
						<el-option v-for="(item,index) in formSearch.cityList" :key="index" :label="item.name" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="流程来源">
					<el-select v-model="formSearch.source" clearable>
						<el-option label="本级自建" value="本级自建"></el-option>
						<el-option label="下级上报" value="下级上报"></el-option>
						<el-option label="上级下发" value="上级下发"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="流程状态">
					<el-select v-model="formSearch.processStatus" clearable>
						<el-option label="已下发" :value="1"></el-option>
						<el-option label="已驳回" :value="2"></el-option>
						<el-option label="结束" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="searchNameFn">检索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<h4 class="title-h4" style="float: left;">企业总数：<span>{{totalItems}}</span> 家</h4>
				<el-popover placement="bottom" width="80" trigger="click" style="float: right;">
					<div style="padding: 15px;box-sizing: border-box;">
						<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
						<div style="margin: 10px 0;"></div>
						<el-checkbox-group v-model="colOptions" @change="handleCheckedCitiesChange">
							<el-checkbox v-for="item in colSelect" :label="item" :key="item"></el-checkbox>
						</el-checkbox-group>
					</div>
					<el-button type="primary" size="mini" icon="iconfont icon-ic_detail" slot="reference" title="列表配置" style="padding: 4px 5px;margin-bottom:5px;"></el-button>
				</el-popover>
				<el-table border v-loading="loadingFlag" :data="tableData" @selection-change="handleSelectionChange" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="selection" width="30"></el-table-column>
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="companyName" label="企业名称" width="250" :show-overflow-tooltip="true" v-if="colData[0].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.isAffirm == 1" @click="companyNameLink(scope.row)" class="color-m" style="cursor: pointer;">{{scope.row.companyName}}</span>
							<span v-if="scope.row.isAffirm == 0">{{scope.row.companyName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="platformName" label="平台名称" :show-overflow-tooltip="true" v-if="colData[1].istrue"></el-table-column>
					<el-table-column prop="warnTime" label="预警时间" :show-overflow-tooltip="true" v-if="colData[2].istrue"></el-table-column>
					<el-table-column prop="processSendTime" label="下发时间" :show-overflow-tooltip="true" v-if="colData[3].istrue"></el-table-column>
					<el-table-column prop="riskIndex" label="风险指数" :show-overflow-tooltip="true" v-if="colData[4].istrue"></el-table-column>
					<el-table-column prop="riskLabels" label="风险点" :show-overflow-tooltip="true" v-if="colData[5].istrue"></el-table-column>
					<el-table-column prop="provinceName" label="注册省" :show-overflow-tooltip="true" v-if="colData[6].istrue"></el-table-column>
					<el-table-column prop="cityName" label="注册地市" :show-overflow-tooltip="true" v-if="colData[7].istrue"></el-table-column>
					<el-table-column prop="refMoney" label="涉及金额" :show-overflow-tooltip="true" v-if="colData[8].istrue"></el-table-column>
					<el-table-column prop="refPerson" label="涉及人数" :show-overflow-tooltip="true" v-if="colData[9].istrue"></el-table-column>
					<el-table-column prop="manageAddress" label="实际经营地" :show-overflow-tooltip="true" v-if="colData[10].istrue"></el-table-column>
					<el-table-column prop="firstIndustry" label="一级行业" :show-overflow-tooltip="true" v-if="colData[11].istrue"></el-table-column>
					<el-table-column prop="secondIndustry" label="二级行业" :show-overflow-tooltip="true" v-if="colData[12].istrue"></el-table-column>
					<el-table-column prop="fundChannel" label="集资渠道" :show-overflow-tooltip="true" v-if="colData[13].istrue"></el-table-column>
					<el-table-column prop="fundWay" label="集资方式" :show-overflow-tooltip="true" v-if="colData[14].istrue"></el-table-column>
					<el-table-column prop="refProvince" label="涉及省份" :show-overflow-tooltip="true" v-if="colData[15].istrue"></el-table-column>
					<el-table-column prop="clueSource" label="线索来源" :show-overflow-tooltip="true" v-if="colData[16].istrue"></el-table-column>
					<el-table-column prop="criminalCaseTime" label="刑事立案时间" :show-overflow-tooltip="true" v-if="colData[17].istrue"></el-table-column>
					<el-table-column prop="adminCaseTime" label="行政立案时间" :show-overflow-tooltip="true" v-if="colData[18].istrue"></el-table-column>
					<el-table-column prop="processCityName" label="下发地市" :show-overflow-tooltip="true" v-if="colData[19].istrue"></el-table-column>
					<el-table-column prop="source" label="流程来源" :show-overflow-tooltip="true" v-if="colData[20].istrue"></el-table-column>
					<el-table-column prop="processStatus" label="流程状态" :show-overflow-tooltip="true" v-if="colData[21].istrue">
						<template slot-scope="scope">
							<span v-show="scope.row.processStatus==1">已下发未签收</span>
							<span v-show="scope.row.processStatus==2">已下发已签收</span>
							<span v-show="scope.row.processStatus==5">下级上报结束</span>
							<span v-show="scope.row.processStatus==6">已驳回待签收</span>
							<span v-show="scope.row.processStatus==7">已驳回已签收</span>
							<span v-show="scope.row.processStatus==9">国家上报结束</span>
						</template>
					</el-table-column>
					<el-table-column prop="rejectReason" label="驳回原因" :show-overflow-tooltip="true" v-if="colData[22].istrue"></el-table-column>
					<el-table-column prop="" label="操作" width="70">
						<template slot-scope="scope">
							<i class="iconfont icon-ziyuan color-m" title="撤回" @click="handleBack(scope.$index, scope.row)" v-show="scope.row.processStatus==1 || scope.row.processStatus==6" style="cursor: pointer;margin: 0 3px;"></i>
							<i class="iconfont icon-chakan3 color-m" title="查看" @click="handleLook(scope.$index, scope.row)" style="cursor: pointer;margin: 0 3px;"></i>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportONE" style="float: left;margin-top:10px;margin-right: 20px;">{{exportOneName}}</el-button>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportALLFn" style="float: left;margin-top:10px;">{{exportAllName}}</el-button>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</el-card>
		</div>
		
		<!-- 查看   弹框 -->
		<el-dialog title="查看" :visible.sync="dialogVisibleBJ" @close="closeDialogVisibleBJ" width="80%" class="madal-box-xietong">
			<el-form :model="ruleFormBJ" ref="ruleFormBJ" label-width="120px" class="demo-ruleForm">
				<el-row :gutter="0">
					<el-col :span="8">
						<el-form-item label="企业名称" prop="companyName">
							<el-input v-model="ruleFormBJ.companyName"></el-input>
						</el-form-item>
						<el-form-item label="平台名称" prop="platformName">
							<el-input v-model="ruleFormBJ.platformName"></el-input>
						</el-form-item>
						<el-form-item label="预警时间" prop="warnTime">
							<el-date-picker type="date" v-model="ruleFormBJ.warnTime" value-format="yyyy-MM-dd"></el-date-picker>
						</el-form-item>
						<el-form-item label="预警指数" prop="riskIndex">
							<el-input v-model="ruleFormBJ.riskIndex"></el-input>
						</el-form-item>
						<el-form-item label="注册省份" prop="provinceId">
							<el-select v-model="ruleFormBJ.provinceId" @change="getCityList">
								<el-option v-for="(item,index) in ruleFormBJ.provinceList" :key="index" :label="item.name" :value="item.code"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="注册地市" prop="cityId">
							<el-select v-model="ruleFormBJ.cityId">
								<el-option v-for="(item,index) in ruleFormBJ.cityList" :key="index" :label="item.name" :value="item.code"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="涉及金额(万元)" prop="refMoney">
							<el-input v-model="ruleFormBJ.refMoney" :precision="2"></el-input>
						</el-form-item>
						<el-form-item label="涉及人数" prop="refPerson">
							<el-input v-model="ruleFormBJ.refPerson"></el-input>
						</el-form-item>
						<el-form-item label="集资渠道" prop="fundChannel">
							<el-select v-model="ruleFormBJ.fundChannel">
								<el-option v-for="(item,index) in ruleFormBJ.fundChannelList" :key="index" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="集资方式" prop="fundWay">
							<el-select v-model="ruleFormBJ.fundWay">
								<el-option v-for="(item,index) in ruleFormBJ.fundWayList" :key="index" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="刑事立案时间" prop="criminalCaseTime">
							<el-date-picker v-model="ruleFormBJ.criminalCaseTime" type="date" value-format="yyyy-MM-dd"></el-date-picker>
						</el-form-item>
						<el-form-item label="行政立案时间" prop="adminCaseTime">
							<el-date-picker v-model="ruleFormBJ.adminCaseTime" type="date" value-format="yyyy-MM-dd"></el-date-picker>
						</el-form-item>
						<el-form-item label="线索来源" prop="clueSource">
							<el-select v-model="ruleFormBJ.clueSource">
								<el-option v-for="(item,index) in ruleFormBJ.clueSourceList" :key="index" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="处置状态" prop="handleStatus">
							<el-select v-model="ruleFormBJ.handleStatus">
								<el-option v-for="(item,index) in ruleFormBJ.handleStatusList" :key="index" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="16">
						<el-form-item label="实际经营地" prop="manageAddress">
							<el-input v-model="ruleFormBJ.manageAddress"></el-input>
						</el-form-item>
						<el-form-item label="行业" prop="industry">
							<el-cascader v-model="ruleFormBJ.industry" :options="ruleFormBJ.industryList" :props="ruleFormBJ.industryProps" ref="industryRef" change-on-select style="width: 100%;"></el-cascader>
						</el-form-item>
						<el-form-item label="涉及省份" prop="refProvince">
							<el-select v-model="ruleFormBJ.refProvince" multiple>
								<el-option
									v-for="item in ruleFormBJ.refProvinceList"
									:key="item.id"
									:label="item.name"
									:value="item.name">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="备注" prop="remark">
							<el-input v-model="ruleFormBJ.remark"></el-input>
						</el-form-item>
						<el-form-item label="风险标签" prop="">
							<div style="width: 100%;height: 550px;overflow: hidden;">
								<el-scrollbar style="height: 100%;width:100%">
									<el-form :inline="true" class="demo-form-inline" ref="">
										<div v-for="(item, index) in ruleFormBJ.riskLabelsList" :key="index">
											<el-form-item label="风险点" prop="tag">
												<el-select v-model="item.tag">
													<el-option v-for="(iem, ind) in ruleFormBJ.tagList" :key="ind" :label="iem.tagName" :value="`${iem.id}&${iem.tagName}`"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="风险来源" prop="url">
												<el-input v-model="item.url" placeholder="请输入风险来源"></el-input>
											</el-form-item>
											<el-form-item label="风险信息" prop="abstract">
												<el-input v-model="item.abstract" placeholder="请输入风险信息" :title="item.abstract"></el-input>
											</el-form-item>
										</div>
									</el-form>
									<div v-if="ruleFormBJ.riskLabelsList.length == 0">
										<img :src="`${baseUrl}img/bt-nodata-now.png`" />
									</div>
								</el-scrollbar>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-dialog>
  </div>
</template>

<script>
import { exportExcel } from '../../provider/index.js' //导出Excel公用方法
export default {
  name: 'already-issue-city',
  data() {
		return {
			loadingFlag: false,
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			pickerOptionsLA: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			formSearch:{
				companyName: '',
				timeLA:null,
				source:'',
				processStatus:'',
				cityCode: '',
				cityList:[],
			},
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
			//列表配置  全选
			isIndeterminate: true,
			checkAll: false,
			//列表配置  列数据
			colData: [
				{istrue: true,title: "企业名称",name:'companyName'},
				{istrue: true,title: "平台名称",name:'platformName'},
				{istrue: true,title: "预警时间",name:'warnTime'},
				{istrue: true,title: "下发时间",name:'editTime'},
				{istrue: true,title: "风险指数",name:'riskIndex'},
				{istrue: true,title: "风险点",name:'riskLabels'},
				{istrue: false,title: "注册省",name:'provinceName'},
				{istrue: false,title: "注册地市",name:'cityName'},
				{istrue: false,title: "涉及金额",name:'refMoney'},
				{istrue: false,title: "涉及人数",name:'refPerson'},
				{istrue: false,title: "实际经营地",name:'manageAddress'},
				{istrue: false,title: "一级行业",name:'firstIndustry'},
				{istrue: false,title: "二级行业",name:'secondIndustry'},
				{istrue: false,title: "集资渠道",name:'fundChannel'},
				{istrue: false,title: "集资方式",name:'fundWay'},
				{istrue: false,title: "涉及省份",name:'refProvince'},
				{istrue: true,title: "线索来源",name:'clueSource'},
				{istrue: false,title: "刑事立案时间",name:'criminalCaseTime'},
				{istrue: false,title: "行政立案时间",name:'adminCaseTime'},
				{istrue: true,title: "下发地市",name:'processCityName'},
				{istrue: true,title: "流程来源",name:'source'},
				{istrue: true,title: "流程状态",name:'processStatus'},
				{istrue: true,title: "驳回原因",name:'rejectReason'},
			],
			colOptions: [],
			colSelect: [],
			multipleSelection: [], //导出参数   数据前  复选框
			exportOneName:"导出所选",
			exportAllName:"导出全部",
			dialogVisibleBJ: false, //是否打开查看弹框
			ruleFormBJ: {
				id: '',
				companyName:'',
				platformName:'',
				warnTime:'',
				riskIndex:'',
				provinceId:'',
				cityId:'',
				provinceList:[],
				cityList:[],
				refMoney:'',
				refPerson:'',
				manageAddress:'',
				firstIndustry:'',
				secondIndustry:'',
				industry:[],
				industryList: [],//行业   数据
				industryProps:{
					value: 'id',
					label: 'name',
					children: 'children'
				},
				fundChannel:'',
				fundChannelList:[],
				fundWay:'',
				fundWaylList:[],
				refProvince:'',
				criminalCaseTime:'',
				adminCaseTime:'',
				clueSource:'',
				clueSourceList:[],
				handleStatus: '',
				handleStatusList:[],
				remark:'',
				riskLabelsList: [],
				tagList:[],//风险点
			},
		};
  },
  mounted() {}, 
  created() {
		//加载下发城市列表
		this.getCurrentProvinceUserCityList();

		//监控 表格  初始  显示列
		for (let i = 0; i < this.colData.length; i++) {
			this.colSelect.push(this.colData[i].title);
			if (this.colData[i].istrue == true) {
				this.colOptions.push(this.colData[i].title);
			}
		}
		this.getPageList(); //加载列表
		//加载   查看   弹框
		this.getProvinceList();//加载弹框  省
		this.getIndustryList();//加载弹框  行业
		this.getFundChannelList();//加载弹框  集资渠道
		this.getFundWayList();//加载弹框  集资方式
		this.getClueSouceList();//加载弹框  线索来源
		this.getHandleStatusList();//加载弹框  处置状态
		this.getRiskTagList();////加载弹框  风险标签   风险点
  },
  watch: {
		//表格控制列显隐
		colOptions(valArr) {
			var arr = this.colSelect.filter(i => valArr.indexOf(i) < 0); // 未选中
			this.colData.filter(i => {
				if (arr.indexOf(i.title) != -1) {
					i.istrue = false;
				} else {
					i.istrue = true;
				}
			});
		}
  },
  methods: {
		//加载下发城市列表
		getCurrentProvinceUserCityList() {
			this.$provider.get('/wg-ifrRisk/dict/getCurrentProvinceUserCityList',{}).then(res=>{
				this.formSearch.cityList = res.data;
			})
		},
		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			let paramStr = this.getParams();
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/provinceProcess/getHadProcessList',{
				params:{
					companyName: paramStr.companyName.replace(/^\s+|\s+$/g,""),
					source: paramStr.source,
					cityCode: paramStr.cityCode,
					processStatus: paramStr.processStatus,
					processSendTimeStart: paramStr.processSendTimeStart,
					processSendTimeEnd: paramStr.processSendTimeEnd,
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res=>{
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		}, 
		//点击机构   跳转详情页
		companyNameLink(item) {
			let user_xjfj = JSON.parse(sessionStorage.getItem("user_xjfj"));
			user_xjfj['defaultActive'] = 1019;
			sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
			const icardUrl = this.$router.resolve({
				path: '/institutionalPortraitInfo',
				query: {
					name: item.companyName,
					id: item.companyId
				}
			})
			window.open(icardUrl.href, '_blank'); //跳转新页面
		},
	
		//撤回
		handleBack(index,item){
			if(confirm("确定撤回本条数据吗？")){
				this.$provider.get('/wg-ifrRisk/provinceProcess/rollbackSendPrecess', {
					params: {
						id: item.id,
					}
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '撤回成功',
							type: 'success'
						});
						this.getPageList();
					} else {
						this.$message.error('撤回失败');
						return false;
					}
				})
			}
		},
		
		//查看 
		handleLook(index,item){
			this.dialogVisibleBJ = true;
			this.ruleFormBJ.id = item.id;
			//加载回显数据
			this.$provider.get('/wg-ifrRisk/provinceProcess/getItemById',{
				params:{
					id:item.id,
				}  
			}).then(res=>{
				this.ruleFormBJ.companyName = res.data.companyName;
				this.ruleFormBJ.platformName = res.data.platformName;
				this.ruleFormBJ.warnTime = res.data.warnTime;
				this.ruleFormBJ.riskIndex = res.data.riskIndex;
				this.ruleFormBJ.provinceId = res.data.provinceId;
				this.getProvinceList();//加载弹框  省
				this.ruleFormBJ.cityId = res.data.cityId;
				this.getCityList(res.data.provinceId);
				this.ruleFormBJ.refMoney = res.data.refMoney;
				this.ruleFormBJ.refPerson = res.data.refPerson;
				this.ruleFormBJ.manageAddress = res.data.manageAddress;
				if(res.data.firstIndustry != "" && res.data.firstIndustry != null){
					this.ruleFormBJ.industry.push(Number(res.data.firstIndustryId));
					if(res.data.secondIndustry != "" && res.data.secondIndustry != null){
						this.ruleFormBJ.industry.push(Number(res.data.secondIndustryId));
					}
				} 
				this.getIndustryList();//加载弹框  行业
				this.ruleFormBJ.fundChannel = res.data.fundChannel;
				this.ruleFormBJ.fundWay = res.data.fundWay;
				this.ruleFormBJ.refProvince = res.data.refProvince!=null&&res.data.refProvince!='' ? res.data.refProvince.split(",") : [];
				this.ruleFormBJ.criminalCaseTime = res.data.criminalCaseTime;
				this.ruleFormBJ.adminCaseTime = res.data.adminCaseTime;
				this.ruleFormBJ.clueSource = res.data.clueSource;
				this.ruleFormBJ.handleStatus = res.data.handleStatus;
				this.ruleFormBJ.remark = res.data.remark;
				//风险标签  回显
				res.data.riskLabelList.filter((item, index) => {
					this.ruleFormBJ.riskLabelsList.push({
						"tag": item.riskId+"&"+item.riskLabel,
						"url": item.url,
						"abstract": item.riskDesc
					})
				})
			})
		},
		//关闭  弹框
		closeDialogVisibleBJ(){
			this.ruleFormBJ.companyName = '';
			this.ruleFormBJ.platformName = '';
			this.ruleFormBJ.warnTime = '';
			this.ruleFormBJ.riskIndex ='';
			this.ruleFormBJ.provinceId = '';
			this.ruleFormBJ.cityId = '';
			this.ruleFormBJ.refMoney = '';
			this.ruleFormBJ.refPerson = '';
			this.ruleFormBJ.manageAddress = '';
			this.ruleFormBJ.industry = [];
			this.ruleFormBJ.fundChannel = '';
			this.ruleFormBJ.fundWay = '';
			this.ruleFormBJ.refProvince = '';
			this.ruleFormBJ.criminalCaseTime = '';
			this.ruleFormBJ.adminCaseTime = '';
			this.ruleFormBJ.clueSource = '';
			this.ruleFormBJ.handleStatus = '';
			this.ruleFormBJ.remark = '';
			this.ruleFormBJ.riskLabelsList = []; //风险标签 
		},
		//加载弹框  省
		getProvinceList() {
			this.$provider.get('/wg-ifrRisk/dict/getAreaList',{
				params:{
					parentId:0
				}
			}).then(res=>{
				this.ruleFormBJ.provinceList = res.data;//注册省份
				this.ruleFormBJ.refProvinceList = res.data;//涉及省份
			})
		},
		//加载弹框  市
		getCityList(id) {
			this.$provider.get('/wg-ifrRisk/dict/getAreaList',{
				params:{
					parentId:id
				}
			}).then(res=>{
				this.ruleFormBJ.cityList = res.data;
				this.ruleFormBJ.cityId = res.data[0].code;
			})
		},
		//加载弹框  行业
		getIndustryList() {
			this.$provider.get('/wg-ifrRisk/dict/getIndustryList',{}).then(res=>{
				this.ruleFormBJ.industryList = res.data;
			})
		},
		//加载弹框  集资渠道
		getFundChannelList() {
			this.$provider.get('/wg-ifrRisk/dict/getFundChannelList',{}).then(res=>{
				this.ruleFormBJ.fundChannelList = res.data;
			})
		},
		//加载弹框  集资方式
		getFundWayList() {
			this.$provider.get('/wg-ifrRisk/dict/getFundWayList',{}).then(res=>{
				this.ruleFormBJ.fundWayList = res.data;
			})
		},
		//加载弹框  线索来源
		getClueSouceList() {
			this.$provider.get('/wg-ifrRisk/dict/getClueSouceList',{}).then(res=>{
				this.ruleFormBJ.clueSourceList = res.data;
			})
		},
		//加载弹框  处置状态
		getHandleStatusList() {
			this.$provider.get('/wg-ifrRisk/dict/getHandleStatusList',{}).then(res=>{
				this.ruleFormBJ.handleStatusList = res.data;
			})
		},
		//加载弹框  风险标签   风险点
		getRiskTagList() {
			this.$provider.get('/wg-ifrRisk/dict/getRiskTagList',{}).then(res=>{
				this.ruleFormBJ.tagList = res.data;
			})
		},
		
		//列表配置   全选
		handleCheckAllChange(val) {
			this.colOptions = val ? this.colSelect : [];
			this.isIndeterminate = false;
		},
		handleCheckedCitiesChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.colSelect.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.colSelect.length;
		},
		//表格 每行  复选框选值    导出
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//导出所选
		exportONE() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				let paramStr = this.getParams();
				let myObj = {
					method: 'get',
					url: this.$pulick.urlStr+'/wg-ifrRisk/provinceProcess/exportSelect',
					fileName: '省份已下发',
					params: 'ids='+chk_value+'&rowType='+paramStr.rowType+'&type=1'
				}
				this.exportOneName = "正在导出中...";
				exportExcel(myObj,this);// 导出Excel公用方法
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
			return false;
			}
		},
		//导出全部
		exportALLFn() {
			let paramStr = this.getParams();
			let myObj = {
				method: 'get',
				url: this.$pulick.urlStr+'/wg-ifrRisk/provinceProcess/exportAll',
				fileName: '省份已下发',
				params: 'companyName='+paramStr.companyName+'&cityCode='+paramStr.cityCode+'&source='+paramStr.source+'&processStatus='+paramStr.processStatus+'&processSendTimeStart='+paramStr.processSendTimeStart+'&processSendTimeEnd='+paramStr.processSendTimeEnd+'&rowType='+paramStr.rowType+'&type=1'
			} 
			this.exportAllName = "正在导出中...";
			exportExcel(myObj,this);// 导出Excel公用方法
		},
		
		//获取参数
		getParams(){
			let rowCol = "";
			this.colData.forEach(function(f, i) {
				if(f.istrue){
					if (i == 0) {
						rowCol = f.name;
					} else {
						rowCol += "," + f.name;
					}
				}
			}) 
			let obj = {
				companyName: this.formSearch.companyName,
				cityCode:this.formSearch.cityCode,
				source: this.formSearch.source,
				processStatus: this.formSearch.processStatus,
				processSendTimeStart: this.formSearch.timeLA != null ? this.formSearch.timeLA[0] : "",
				processSendTimeEnd: this.formSearch.timeLA != null ? this.formSearch.timeLA[1] : "",
				rowType:rowCol
			}
			return obj;
		}
  }
};
</script>

<style lang="stylus" scoped="scoped"></style>
